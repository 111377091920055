import React, { createContext, useEffect, useState, useContext } from 'react'
import { useWeb3 } from '@/context/web3'

const ServerContext = createContext({
  isRegistered: false,
  receiverData: {},
  userData: {},
  registerUser: (userData) => {},
  findUser: (findAccount) => {},

  // registeredUsers: [],
  // deleteAccount: (user) => {},
})

export const useServer = () => useContext(ServerContext)

const ServerProvider = ({ children }) => {
  const URL = process.env.REACT_APP_SERVER_URL || 'http://localhost:4000'
  const { userAddress } = useWeb3()
  const [userData, setUserData] = useState({})
  const [isRegistered, setIsRegistered] = useState(false)
  const [findAddress, setFindAddress] = useState('')
  const [receiverData, setReceiverData] = useState({})

  const findUser = (findAccount = userAddress) => {
    setFindAddress(findAccount)
  }

  const handleUserFound = (user, userAddress) => {
    setIsRegistered(!user.error ? true : false)
    // console.log('Found this address', user.address, findAddress)
    findAddress === userAddress
      ? setUserData(!user.error ? user : {})
      : setReceiverData(user.address !== findAddress ? user : {})
  }

  // ? Register a new user to db
  const registerUser = (userData) => {
    if (userData.publicKey !== undefined && userData.address !== undefined) {
      fetch(URL + '/register', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: userData,
      })
        .then((res) => res.json())
        .then((data) => console.log(data))
      setIsRegistered(true)
    }
  }

  // * Get user details from his address
  useEffect(() => {
    if (findAddress.length >= 40) {
      fetch(URL + '/user/' + findAddress)
        .then((res) => res.json())
        .then((user) => handleUserFound(user, userAddress))
    }
  }, [userAddress, URL, findAddress])

  useEffect(() => {
    findUser()
  }, [userAddress, findAddress])

  return (
    <ServerContext.Provider value={{ isRegistered, registerUser, findUser, userData, receiverData }}>
      {children}
    </ServerContext.Provider>
  )
}

const LayoutContext = createContext({
  isMobile: false,
})

export const useLayout = () => useContext(LayoutContext)

export const LayoutProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(process.browser ? window.innerWidth < 768 : false)

  const onResize = () => {
    if (window.innerWidth < 768 && !isMobile) {
      setIsMobile(true)
    } else if (window.innerWidth >= 768 && isMobile) {
      setIsMobile(false)
    }
  }

  useEffect(onResize, [])
  useEffect(() => {
    window.addEventListener('resize', onResize)

    const unsubscribe = () => {
      window.removeEventListener('resize', onResize)
    }
    return unsubscribe
  })

  return <LayoutContext.Provider value={{ isMobile }}>{children}</LayoutContext.Provider>
}

export default ServerProvider
