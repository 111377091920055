export const TextStyles = {
  Desktop: {
    Headings: {
      H1: {
        FontFamily: 'Clash Display, sans-serif',
        FontSize: '3.068rem',
        FontWeight: 800,
        LineHeight: '3.63rem',
      },
      H2: {
        FontFamily: 'Clash Display, sans-serif',
        FontSize: '2.727rem',
        FontWeight: 700,
        LineHeight: '3.23rem',
      },
      H3: {
        FontFamily: 'Clash Display, sans-serif',
        FontSize: '1.988rem',
        FontWeight: 700,
        LineHeight: '2.386rem',
      },
      H4: {
        FontFamily: 'Clash Display, sans-serif',
        FontSize: '1.59rem',
        FontWeight: 700,
        LineHeight: '1.875rem',
      },
      H5: {
        FontFamily: 'Clash Display, sans-serif',
        FontSize: '1.36rem',
        FontWeight: 700,
        LineHeight: '1.36rem',
      },
    },
    Body: {
      Regular21: {
        FontFamily: 'Lato, sans-serif',
        FontSize: '1.125rem',
        FontWeight: 400,
        LineHeight: '1.38375rem',
      },
      Regular20: {
        FontFamily: 'Clash Display, sans-serif',
        FontSize: '1.125rem',
        FontWeight: 400,
        LineHeight: '1.38375rem',
      },
      Regular18: {
        FontFamily: 'Clash Display, sans-serif',
        FontSize: '1.0227rem',
        FontWeight: 300,
        LineHeight: '1.420rem',
      },
      Regular16: {
        FontFamily: 'Clash Display, sans-serif',
        FontSize: '0.909rem',
        FontWeight: 300,
        LineHeight: '1.363rem',
      },
      Regular15: {
        FontFamily: 'Clash Display, sans-serif',
        FontSize: '0.852rem',
        FontWeight: 300,
        LineHeight: '1.25rem',
      },
      Regular13: {
        FontFamily: 'Clash Display, sans-serif',
        FontSize: '0.738rem',
        FontWeight: 300,
        LineHeight: '1.136rem',
      },
      Regular12: {
        FontFamily: 'Clash Display, sans-serif',
        FontSize: '0.681rem',
        FontWeight: 300,
        LineHeight: '1.0227rem',
      },
    },
    Buttons: {
      Semi28: {
        FontFamily: 'Clash Display, sans-serif',
        FontSize: '1.59rem',
        FontWeight: 600,
        LineHeight: '2.045rem',
      },
      Semi24: {
        FontFamily: 'Clash Display, sans-serif',
        FontSize: '1.363rem',
        FontWeight: 600,
        LineHeight: '1.70rem',
      },
      Semi20: {
        FontFamily: 'Clash Display, sans-serif',
        FontSize: '1.136rem',
        FontWeight: 600,
        LineHeight: '1.420rem',
      },
      Semi18: {
        FontFamily: 'Clash Display, sans-serif',
        FontSize: '1.022rem',
        FontWeight: 600,
        LineHeight: '1.306rem',
      },
    },
  },
  Mobile: {
    Headings: {
      H1: {
        FontFamily: 'Clash Display, sans-serif',
        FontSize: '2.1875rem',
        FontWeight: 700,
        LineHeight: '2.625rem',
      },
      H2: {
        FontFamily: 'Clash Display, sans-serif',
        FontSize: '1.75rem',
        FontWeight: 700,
        LineHeight: '2.0625rem',
      },
      H3: {
        FontFamily: 'Clash Display, sans-serif',
        FontSize: '1.5rem',
        FontWeight: 700,
        LineHeight: '1.8125rem',
      },
      H4: {
        FontFamily: 'Clash Display, sans-serif',
        FontSize: '1.25rem',
        FontWeight: 700,
        LineHeight: '1.5rem',
      },
      H5: {
        FontFamily: 'Clash Display, sans-serif',
        FontSize: '1.125rem',
        FontWeight: 700,
        LineHeight: '1.3125rem',
      },
    },
    Body: {
      Regular16: {
        FontFamily: 'Lato, sans-serif',
        FontSize: '1.022rem',
        FontWeight: 400,
        LineHeight: '1.5rem',
      },
      Regular15: {
        FontFamily: 'Clash Display, sans-serif',
        FontSize: '0.9375rem',
        FontWeight: 300,
        LineHeight: '1.3125rem',
      },
      Regular13: {
        FontFamily: 'Clash Display, sans-serif',
        FontSize: '0.8125rem',
        FontWeight: 300,
        LineHeight: '1.125rem',
      },
      Regular12: {
        FontFamily: 'Clash Display, sans-serif',
        FontSize: '0.75rem',
        FontWeight: 300,
        LineHeight: '1.0625rem',
      },
      Regular11: {
        FontFamily: 'Clash Display, sans-serif',
        FontSize: '0.6875rem',
        FontWeight: 300,
        LineHeight: '0.9375rem',
      },
      Regular9: {
        FontFamily: 'Clash Display, sans-serif',
        FontSize: '0.5625rem',
        FontWeight: 300,
        LineHeight: '0.8125rem',
      },
    },
    Buttons: {
      Semi18: {
        FontFamily: 'Clash Display, sans-serif',
        FontSize: '1.125rem',
        FontWeight: 600,
        LineHeight: '1.4375rem',
      },
      Semi16: {
        FontFamily: 'Clash Display, sans-serif',
        FontSize: '1rem',
        FontWeight: 600,
        LineHeight: '1.25rem',
      },
    },
  },

  Bundler: function (attr) {
    return `
        font-family: ${attr.FontFamily};
        font-size: ${attr.FontSize};
        font-weight:  ${attr.FontWeight};
        line-height: ${attr.LineHeight};
        `
  },
}

export const ColorStyles = {
  Cards: {
    LightGrayCard: '#393939',
    GrayCardBg: '#1A1A1A',
    DarkGray: '#131313',
    GlassCardStroke: '#FFFFFF',
    GlassCard: 'linear-gradient(143.93deg, rgba(250, 250, 250, 0.53) 11.44%, rgba(239, 239, 239, 0) 100%)',
  },
  Gradients: {
    Radial: 'radial-gradient(45.65% 45.65% at 50% 54.21%, #000000 0%, rgba(0, 0, 0, 0) 100%)',
    LightToDark: 'linear-gradient(0deg, #151515 0%, rgba(21, 21, 21, 0.83) 66.78%, rgba(8, 8, 8, 0) 96.74%)',
    LinearText: 'linear-gradient(270.55deg, #FFFFFF -4.34%, rgba(255, 255, 255, 0.77) 138.3%)',
  },
  Colors: {
    Cream: '#D9D9D9',
    White: '#FFFFFF',
    Black: '#000000',
    Gray: '#868686',
    NeutralGray: '#262626',
    NeutralGrayLight: '3A3A3A',
    SubtleNeutral: '#414141',
    DarkGray: '#1C1C1C',
    MainBg: '#151515',
    LightGray: '#696969',
    PureGray: '#191919',
    Buttons: {
      Filled: {
        Disabled: '#DEE8E6',
        ActiveState: 'linear-gradient(92.23deg, #08A97C 0.48%, #0AE496 103.21%)',
        HoverState: 'linear-gradient(92.23deg, #0F8563 0.48%, #0EC685 103.21%)',
      },
      Outline: {
        Disabled: '#DEE8E6',
        ActiveState: 'linear-gradient(92.23deg, #08A97C 0.48%, #0AE496 103.21%)',
        HoverState: '#EAF8F3',
      },
    },
    Text: {
      TextPrimary: '#EBEBEB',
      Gray: '#A9A9A9',
      LightGray: '#a5a5a5',
      White: '#ffffff',
      DarkGrayIcon: '#575757',
    },
    Cards: {
      StrokeGray: '#1E1E1E',
    },
  },
  BrandColors: {
    Main: '#D5FA50',
    Red: '#E63946',
    Glowgrin: '#28CFB8',
    Graygrin: '#41A497',
    Darkblue: '#1D3557',
  },
}

export const EffectStyles = {
  GrayEllipse: {
    background: '#D9D9D9',
    opacity: '0.5',
    zIndex: -1,
    position: 'absolute',
    filter: 'blur(322px)',
  },
  GlassCardBlur: {
    Background: '#D9D9D9',
    BackdropFilter: 'blur(25px)',
  },
  Shadows: {
    ShadowCard: {
      Background: '#D9D9D9',
      BoxShadow: '1.5px 1.5px 6px rgba(0, 0, 0, 0.09)',
    },
    SmoothShadow: {
      Background: '#D9D9D9',
      BoxShadow: '6px 6px 15px rgba(0, 0, 0, 0.06)',
    },
    PerformanceCard: {
      Background: '#FFFFFF',
      BoxShadow: '3.98242px 3.98242px 9.95604px rgba(0, 0, 0, 0.06)',
    },
    Bundler: function (attr) {
      return `
        background: ${attr.Background};
        box-shadow:  ${attr.BoxShadow};
        `
    },
  },
  Bundler: function (attr) {
    return `
        background: ${attr.Background};
        backdrop-filter:  ${attr.BackdropFilter};
        `
  },
}

export const MediaQueries = {
  xs: '458px',
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  '2xl': '1536px',
  Bundler: function (attr) {
    return `
        @media screen and (min-width:${attr})
      `
  },
}
