import { createGlobalStyle } from 'styled-components'
import { ColorStyles, TextStyles } from './theme'

const GlobalStyle = createGlobalStyle`

* {  
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Clash Display", sans-serif;
}

:root {
    @font-face {
    font-family: "Clash Display", sans-serif;
    font-weight: 200;
    font-display: swap;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Clash Display", sans-serif;
    font-weight: 300;
    font-display: swap;
    font-style: normal;
  }
  
  
  @font-face {
    font-family: "Clash Display", sans-serif;
    font-weight: 400;
    font-display: swap;
    font-style: normal;
  }
  
  
  @font-face {
    font-family: "Clash Display", sans-serif;
    font-weight: 500;
    font-display: swap;
    font-style: normal;
  }
  
  
  @font-face {
    font-family: "Clash Display", sans-serif;
    font-weight: 600;
    font-display: swap;
    font-style: normal;
  }
  
  
  @font-face {
    font-family: "Clash Display", sans-serif;
    font-weight: 700;
    font-display: swap;
    font-style: normal;
  }
}


a {
  text-decoration: none;
  color: ${ColorStyles.Colors.Text.DarkGray};
}

button {
  cursor: pointer;
}

body {
  ${TextStyles.Bundler(TextStyles.Mobile.Body.Regular15)}
  background-color: ${ColorStyles.Colors.MainBg};
  @media screen and (min-width: 768px) {
      ${TextStyles.Bundler(TextStyles.Desktop.Body.Regular16)}
  }
}

::-webkit-scrollbar {
  width: 0.75rem;
  height: 100vh;
  background-color: ${ColorStyles.Colors.MainBg};
  margin-block: 0.25em;
}

::-webkit-scrollbar-track {
  margin-block: 0.25em;
}

::-webkit-scrollbar-thumb {
  border: 0.005 solid rgba(255, 255, 255, 0);
  border-radius: 0.009rem;
  background-clip: padding-box;
  background-color: ${ColorStyles.BrandColors.Main};
}

::-webkit-scrollbar-thumb:hover {
  background-color: #abcc2f;
}

@supports (scrollbar-color: ${ColorStyles.BrandColors.Main} #000000e5) {
  * {
    scrollbar-color: ${ColorStyles.BrandColors.Main} #000000e5;
    scroll-behavior: smooth;
  }
}

:: -moz-selection {
  color:  ${ColorStyles.BrandColors.Main};
  background:  ${ColorStyles.Colors.DarkGray};
}

:: selection {
  color:  ${ColorStyles.BrandColors.Main};
  background:  ${ColorStyles.Colors.DarkGray};
}

`

export default GlobalStyle
