import React, { Suspense } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
const Mails = React.lazy(() => import('@/pages/Mails'))
const Home = React.lazy(() => import('@/pages/Home'))

function App() {
  return (
    <Suspense fallback={'Loading...'}>
      <Router>
        <Routes>
          <Route path="/mail" element={<Mails />}>
            <Route index element={<Mails />} />
            <Route path="inbox" element={<Mails />} />
            <Route path="inbox/:inboxMail" element={<Mails />} />
            <Route path="sent" element={<Mails />} />
            <Route path="sent/:sentMail" element={<Mails />} />
            <Route path="drafts" element={<Mails />} />
            <Route path="drafts/:draftMail" element={<Mails />} />
          </Route>
          <Route path="*" element={<Home />} />
        </Routes>
      </Router>
    </Suspense>
  )
}

export default App
