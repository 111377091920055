import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import Web3Provider from '@/context/web3'
import ServerProvider, { LayoutProvider } from './context/AppCtx'
import GlobalStyle from '@/styles/globalStyle'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    {/* Strict Mode wraps everything */}
    <Web3Provider>
      <ServerProvider>
        {/* We want the web3 data to be available globally so keeping it a higher in tree  */}
        <GlobalStyle />
        <LayoutProvider>
          <App />
        </LayoutProvider>
      </ServerProvider>
    </Web3Provider>
  </React.StrictMode>
)
