import React from 'react'
import { ethers } from 'ethers'
import { createContext, useContext, useEffect, useState } from 'react'
import { blockExplorerUrls, rpcUrls, networkToken, networkTokenName, networkName, chainId } from '@/constants'
import { formatEther, parseEther } from 'ethers/lib/utils'

const defaultProvider = new ethers.providers.JsonRpcProvider(rpcUrls[chainId][0])

const Web3Context = createContext({
  provider: defaultProvider,
  initWallet: () => { },
  signer: null,
  wallet: null,
  userAddress: '',
})

export const useWeb3 = () => useContext(Web3Context)

const Web3Provider = ({ children }) => {
  const [signer, setSigner] = useState(null)
  const [wallet, setWallet] = useState(null)
  const [provider, setProvider] = useState(defaultProvider)
  const [userAddress, setUserAddress] = useState('')

  const handleAccountChange = ([account]) => setUserAddress(window.ethereum.selectedAddress || account)

  const prepareWallet = async (wallet) => {
    try {
      if (wallet.chainId !== chainId) {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain', // this fallbacks to wallet_switchEthereumChain
          params: [
            {
              chainId: chainId,
              chainName: networkName[chainId],
              rpcUrls: rpcUrls[chainId],
              blockExplorerUrls: blockExplorerUrls[chainId],
              nativeCurrency: {
                name: networkTokenName[chainId],
                symbol: networkToken[chainId],
                decimals: 18,
              },
            },
          ],
        })
      }

      const [account] = await window.ethereum.request({
        method: 'eth_requestAccounts',
      })

      const currentAddress = window.ethereum.selectedAddress || account
      setWallet(wallet)
      setUserAddress(currentAddress)
      setProvider(new ethers.providers.Web3Provider(wallet))
    } catch (err) {
      console.error(err.message)
    }
  }

  const initWallet = () => {
    const provider = window.ethereum
    if (!provider) return window.alert('Please install Metamask to use the app!')

    window.ethers = ethers
    window.parseEther = parseEther
    window.formatEther = formatEther

    if (provider) {
      prepareWallet(provider)

      const callBack = () => {
        window.ethereum.removeAllListeners('chainChanged')
        window.ethereum.removeAllListeners('accountsChanged')
      }

      window.ethereum.on('chainChanged', () => window.location.reload())
      window.ethereum.on('accountsChanged', handleAccountChange)

      return callBack
    }
  }

  useEffect(() => {
    if (!!userAddress) {
      setSigner(provider.getSigner(userAddress))
    }
  }, [userAddress, provider])

  // Comment this line to disable automatic popup
  useEffect(initWallet, [])

  return (
    <Web3Context.Provider value={{ userAddress, wallet, provider, signer, initWallet }}>
      {children}
    </Web3Context.Provider>
  )
}

export default Web3Provider
