import { toHex } from '@/utils'

export const ChainId = {
  POLYGON: toHex(137),
  GODWOKEN_TESTNET: toHex(71401),
  GODWOKEN: toHex(71402),
}

export const rpcUrls = {
  [ChainId.POLYGON]: ['https://rpc-mainnet.matic.quiknode.pro'],
  [ChainId.GODWOKEN_TESTNET]: ['https://godwoken-testnet-v1.ckbapp.dev'],
  [ChainId.GODWOKEN]: ['https://v1.mainnet.godwoken.io/rpc'],
}

export const blockExplorerUrls = {
  [ChainId.POLYGON]: ['https://polygonscan.com'],
  [ChainId.GODWOKEN_TESTNET]: ['https://gw-testnet-explorer.nervosdao.community'],
  [ChainId.GODWOKEN]: ['https://gw-mainnet-explorer.nervosdao.community'],
}

export const networkName = {
  [ChainId.POLYGON]: 'Polygon Mainnet',
  [ChainId.GODWOKEN_TESTNET]: 'Godwoken Testnet L2',
  [ChainId.GODWOKEN]: 'Godwoken Mainnet L2',
}

export const networkTokenName = {
  [ChainId.POLYGON]: 'Matic',
  [ChainId.GODWOKEN_TESTNET]: 'GW (testnet)',
  [ChainId.GODWOKEN]: 'Godwoken',
}

export const networkToken = {
  [ChainId.POLYGON]: 'ETH',
  [ChainId.GODWOKEN_TESTNET]: 'pCKB',
  [ChainId.GODWOKEN]: 'pCKB',
}

export const mailboxContractAddresses = {
  [ChainId.POLYGON]: '0x0000000000000000000000000000000000000000',
  [ChainId.GODWOKEN_TESTNET]: '0xf2BF94eF992f5512EEC3fA36d0221E845ed9BF79',
  [ChainId.GODWOKEN]: '0xA049F14E503A489E6f72603034CBe4d6835C8393',
}

export const chainId = process.env.REACT_APP_CHAIN_ID ? toHex(process.env.REACT_APP_CHAIN_ID) : ChainId.GODWOKEN
